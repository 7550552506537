import React from "react";

import Navigation from "../components/Navigation";

import video_test from "../assets/video/Scambio.mp4";
import img from "../assets/scambio.jpg";
import LateralMenu from "../components/LateralMenu";

export default function P10() {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        backgroundColor: "white",
      }}
    >
      <LateralMenu video={video_test} selected={4} />
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "8%",
          paddingRight: "8%",
        }}
      >
        <ul style={{ color: "#333333", fontSize: "1.5em", fontWeight: 100 }}>
          <li style={{ marginBottom: 30, marginTop: 30 }}>
            Lo scambio è virtuale
          </li>
          <li style={{ marginBottom: 30, marginTop: 30 }}>
            Vengono comunicati al GSE i POD
          </li>
          <li style={{ marginBottom: 30, marginTop: 30 }}>
            Ora per ora si saprà quanta energia avrà prodotto e immesso oppure
            consumato ciascuno dei membri della CER.
          </li>
        </ul>
        <div>
          <img src={img} width={"100%"}></img>
        </div>
      </div>

      <Navigation back="/p9" forward="/p11" />
    </div>
  );
}
