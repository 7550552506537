import React from "react";
import { motion } from "framer-motion";

import Assistant from "../components/Assistant";
import Navigation from "../components/Navigation";

import video_test from "../assets/video/Giornata_tipo.mp4";
import bgImg from "../assets/sfondoP1-1.jpg";
import bgImg2 from "../assets/sfondoP1-2.jpg";
import bgImg3 from "../assets/sfondoP1-3.jpg";
import bgImg4 from "../assets/sfondoP1-4.jpg";
import bgImg5 from "../assets/sfondoP1-5.jpg";

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      delayChildren: 0,
      staggerChildren: 4,
    },
  },
};

const firstChild = {
  hidden: { opacity: 1 },
  visible: {
    opacity: [1, 1, 0],
    transition: { duration: 5, times: [0, 0.8, 1] },
  },
};

const midChild = {
  hidden: { opacity: 0 },
  visible: {
    opacity: [0, 1, 1, 0],
    transition: { duration: 5, times: [0, 0.2, 0.8, 1] },
  },
};

const lastChild = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 1 },
  },
};

function P1() {
  return (
    <motion.div
      style={{ height: "100%" }}
      variants={containerVariants}
      animate="visible"
      initial="hidden"
    >
      <motion.div
        variants={firstChild}
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></motion.div>

      <motion.div
        variants={midChild}
        style={{
          backgroundImage: `url(${bgImg2})`,
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></motion.div>

      <motion.div
        variants={midChild}
        style={{
          backgroundImage: `url(${bgImg3})`,
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></motion.div>

      <motion.div
        variants={midChild}
        style={{
          backgroundImage: `url(${bgImg4})`,
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></motion.div>

      <motion.div
        variants={lastChild}
        style={{
          backgroundImage: `url(${bgImg5})`,
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></motion.div>

      <Assistant
        src={video_test}
        position={{
          zIndex: 10,
          position: "absolute",
          bottom: "30px",
          left: "30px",
        }}
      />
      <Navigation back="/intro_aris" forward="/p2" />
    </motion.div>
  );
}

export default P1;
