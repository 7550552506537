import React, { useState } from "react";
import { Button, Modal } from "@mui/material";

import Navigation from "../components/Navigation";

//import bgImg from "../assets/sfondoWelcome.jpg";
import bgImg from "../assets/sfondoIntro.jpg";
import logo from "../assets/light_logo.png";
import forwardIcon from "../assets/forward.png";
import audioIcon from "../assets/audio_icon.png";
import spieg1Img from "../assets/spieg1.png";
import spieg2Img from "../assets/spieg2.png";

export default function Welcome() {
  const nSlides = 2;
  const [open, setOpen] = useState(true);
  const [slide, setSlide] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  const nextSlide = () => {
    const tmp = (slide + 1) % nSlides;
    setSlide(tmp);
  };

  return (
    <>
      <Modal open={open} onClose={() => {}}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            backgroundColor: "#fff",
            border: "unset",
            borderRadius: 10,
            padding: "1.5rem",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <p
            style={{
              margin: 0,
              fontSize: "1.15rem",
              fontWeight: 500,
              color: global.blueColor,
            }}
          >
            Poche semplici istruzioni per guidare la sessione:
          </p>
          <Spiegazione slide={slide} />

          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              justifyContent: "center",
            }}
          >
            {Array(nSlides)
              .fill(1)
              .map((item, index) => {
                return (
                  <div
                    style={{
                      width: 30,
                      height: 10,
                      borderRadius: 10,
                      border: "2px solid #0097A7",
                      background: index == slide ? "#0097A7" : null,
                    }}
                  ></div>
                );
              })}
          </div>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              gap: "2rem",
              justifyContent: "center",
            }}
          >
            <Button size="large" variant="contained" onClick={nextSlide}>
              Continua
            </Button>
            <Button size="small" variant="outlined" onClick={handleClose}>
              Salta la spiegazione
            </Button>
          </div>
        </div>
      </Modal>
      <div
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          color: "white",
          fontSize: "5rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p style={{ margin: 0 }}>Aris e CERtrude</p>
        <p style={{ margin: 0 }}>vi danno il benvenuto!</p>
      </div>
      <Navigation forward="/intro" />
    </>
  );
}

function Spiegazione({ slide }) {
  switch (slide) {
    case 0:
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
          }}
        >
          <div>
            <img src={spieg1Img} width={300} />
          </div>
          <div>
            <p>
              Per passare alla pagina successiva, posiziona il mouse vicino al
              bordo destro dello schermo. Il cursore del mouse si trasformerà in
              questa icona
              <img src={forwardIcon} style={{ padding: "0 6px" }}></img> e
              potrai cliccare per continuare la sessione con la prossima pagina.
            </p>
          </div>
        </div>
      );
      break;
    case 1:
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
          }}
        >
          <div>
            <img src={spieg2Img} width={300} />
          </div>
          <div>
            <p>
              Per sentire la spiegazione di CERtrude, posizionati col mouse
              sulla sua immagine. Il cursore del mouse si trasformerà in questa
              icona
              <img src={audioIcon} style={{ padding: "0 6px" }}></img> e potrai
              cliccare per ascoltare i preziosi consigli di CERtrude.
            </p>
          </div>
        </div>
      );
      break;
  }
}
