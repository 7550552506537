import React from "react";

import Navigation from "../components/Navigation";

import video_test from "../assets/video/Legge.mp4";
import LateralMenu from "../components/LateralMenu";

export default function P8() {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        backgroundColor: "white",
      }}
    >
      <LateralMenu video={video_test} selected={1} />
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "8%",
          paddingRight: "8%",
        }}
      >
        <ol style={{ color: "#333333", fontSize: "1.5em", fontWeight: 100 }}>
          <li style={{ marginBottom: 30, marginTop: 30 }}>
            “<span style={{ fontWeight: 700 }}>Soggetti</span> di diritto
            autonomo”, come ad esempio associazioni, cooperative, fondazioni,
            etc…
          </li>
          <li style={{ marginBottom: 30, marginTop: 30 }}>
            Con “l'<span style={{ fontWeight: 700 }}>obiettivo</span> principale
            di fornire benefici ambientali, economici o sociali a livello di
            comunità ai suoi soci o membri o alle aree locali in cui opera la
            comunità e non quello di realizzare profitti finanziari”
          </li>
          <li style={{ marginBottom: 30, marginTop: 30 }}>
            <span style={{ fontWeight: 700 }}>Aperte</span> “a tutti i
            consumatori, compresi quelli appartenenti a famiglie a basso reddito
            o vulnerabili”.
          </li>
          <li style={{ marginBottom: 30, marginTop: 30 }}>
            Per le imprese, la partecipazione alla CER “non può costituire
            l'attività commerciale e industriale principale”
          </li>
        </ol>
      </div>

      <Navigation back="/p7" forward="/p8_aris" />
    </div>
  );
}
