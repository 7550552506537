import React from "react";

import Navigation from "../components/Navigation";

import video_test from "../assets/video/Unicita.mp4";
import img from "../assets/unicaCER.png";
import LateralMenu from "../components/LateralMenu";

export default function P14() {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        backgroundColor: "white",
      }}
    >
      <LateralMenu video={video_test} selected={8} />
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "8%",
          paddingRight: "8%",
        }}
      >
        <img src={img} width={"80%"}></img>
      </div>

      <Navigation back="/p13" forward="/p15" />
    </div>
  );
}
